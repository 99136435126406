import { template as template_71c03afb8e124acca2d177b2edf18cab } from "@ember/template-compiler";
const SidebarSectionMessage = template_71c03afb8e124acca2d177b2edf18cab(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
