import { template as template_2a6da58759d7459ab4b8932710b52f87 } from "@ember/template-compiler";
const FKText = template_2a6da58759d7459ab4b8932710b52f87(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
